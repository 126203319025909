import styled from '@emotion/styled'
import Link from 'next/link'

import Button from 'components/Button'

const StyledLink = styled(Link)`
	color: inherit;
	text-decoration: none;
`

function NotFound() {
	return (
		<>
			<h1>Not Found</h1>
			<p>The page you want to visit does not exist</p>
			<Button kind="primary">
				<StyledLink href="/">Back to Homepage</StyledLink>
			</Button>
		</>
	)
}

export default NotFound
